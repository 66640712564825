import { useLocation, Link } from 'react-router-dom';

const Final = () => {
    const location = useLocation();
    const iScore = parseInt(location.state.score ?? 0);

    let result = "<p>Your ability to spot AI-generated images is impressive.</p>";
    if(iScore < 7) {
        result = "<p>You've got a good eye for detail.</p><p>Try again and see if you can improve your score.</p>";
    }
    if(iScore < 5) {
        result = "<p>You'll get the hang of it with more practice.</p>";
    }

	return (
        <div className="pink-wrap">
            <div className="container final circle-wrap circle--full">
                <div dangerouslySetInnerHTML={{ __html: result }}></div>
                <p>How would you use this technology to improve life for humans?</p>
                <Link className="restart" to="/">Let's start again <span>→</span></Link>
            </div>
        </div>
	);
};

export default Final;