const NotFound = () => {
	return (
        <div className="pink-wrap circle-wrap circle--full">
            <div className="container centered">
                <h1>Page not found</h1>
            </div>
        </div>
	);
};

export default NotFound;