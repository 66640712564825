import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Image from "../Images/WhanauDay.png";
import TypedMessage from "../Components/TypedMessage";
import Delayed from "../Components/Delayed";
import { AutoTextSize } from 'auto-text-size'

const Hello = () => {
    const [searchParams] = useSearchParams(window.location.search);

    // strip dodgy chars and limit to 20 chars
    const fname = (searchParams.get("firstname") ?? "nobody").replace(/[^a-z0-9\-'. ]/gi, '').substring(0, 19);
	
    const [showMsg, setShowMsg] = useState(false);
	const navigate = useNavigate();

	const cbStartScanAnimation = function() {
		setTimeout(
			function(){
				document.querySelector('.pink-wrap').classList.add('scanning');
				document.querySelector('.content-step1').classList.add('hidden');
				setShowMsg( true );
			}, 2000
		);
	};
	const cbStopScanAnimation = function() {
		setTimeout(
			function(){
				let elApp = document.querySelector('.pink-wrap');
				elApp.classList.add('moveUp');
				elApp.classList.remove('scanning');

				setTimeout(
					function(){
						navigate("/quiz");
					}, 2000
				);
			}, 2000
		);
	};

	return (		
        <div className="pink-wrap">
			<div className="container hello">
				<div className="content-step1">
					<div className="title">
						<p className="greeting">Kia ora,</p>

						<div className="username" style={{ maxWidth: "100%" }}>
						<AutoTextSize mode={'oneline'} maxFontSizePx={800}>
							{fname}
						</AutoTextSize>
						</div>
					</div>
					<Delayed waitBeforeShow={2000}>
						<TypedMessage msg={"Lets check that you are human. Please stand still for a second!"} done={cbStartScanAnimation} />
					</Delayed>
				</div>
				<div className="content-step2">
					{ showMsg ? <Delayed waitBeforeShow={2000}><TypedMessage msg={"Human detected."} done={cbStopScanAnimation} /></Delayed> : null }
				</div>
			</div>
			<img className={"imgScanning"} src={Image} alt={"bg"} />		
		</div>
	);
};

export default Hello;