import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Image1 from "../Images/fake/dl1.jpg";
import Image2 from "../Images/fake/dl2.jpg";
import Image3 from "../Images/fake/dl3.jpg";
import Image4 from "../Images/fake/dl4.jpg";
import Image5 from "../Images/fake/dl5.jpg";
import Image6 from "../Images/real/dl1.jpg";
import Image7 from "../Images/real/dl2.jpg";
import Image8 from "../Images/real/dl3.jpg";
import Image9 from "../Images/real/dl4.jpg";
import Image10 from "../Images/real/dl5.jpg";

const Quiz = () => {
    const [done, setDone] = useState(0);
    const [score, setScore] = useState(0);
    const [current, setCurrent] = useState(0);
    const [showScores, setShowScores] = useState(0);
    const [summary, setSummary] = useState('');
    const [result, setResult] = useState('');

    const images = [{src: Image1, ans: 'Fake'},
                    {src: Image6, ans: 'Real'},
                    {src: Image7, ans: 'Real'},
                    {src: Image2, ans: 'Fake'},
                    {src: Image3, ans: 'Fake'},
                    {src: Image8, ans: 'Real'},
                    {src: Image4, ans: 'Fake'},
                    {src: Image9, ans: 'Real'},
                    {src: Image5, ans: 'Fake'},
                    {src: Image10, ans: 'Real'}];
    
    const imgLen = images.length;
	const navigate = useNavigate();
    
    const handleClick = (e) => {
        if(!done) {
            if(e.target.innerText === images[current].ans) {
                setScore(score => score + 1);
            }
            
            if(current < imgLen - 1) {
                setCurrent(current => current + 1);
            }
            else {
                setDone(1);
            }
        }
    };

    useEffect(() => {
        if(done) {
            // otherwise show the final score & disable btns
            setTimeout(function(){
                setShowScores(1);
                
                let headline = "Wow";
                let summary = "You nailed it!";

                if(score < 7) {
                    headline = "Nice!";
                    summary = "Not bad at all";
                }
                if(score < 5) {
                    headline = "Uh-oh";
                    summary = "AI is tricky to spot sometimes!";
                }
                setResult(headline);

                setTimeout(function(){
                    setSummary(summary);
                    document.querySelector('.scoreWrap').classList.remove('hidden');

                    setTimeout(
                        function(){
                            navigate("/final", {state:{score:score}});
                        }, 4000
                    );
                }, 1500);

            }, 1000);
        }
    }, [current, done, imgLen, navigate, score]);

	return (
        <div className="pink-wrap">
            <div className={`container circle-wrap quiz ` + (done ? 'fade ' : '') + (showScores ? 'hidden' : '')}>
                <div className="imgWrap">
                    <img src={images[current].src} alt="bg" />
                </div>
                <div className="formWrap">
                    <p>Is this a real person or is it AI? Take a guess.</p>
                    <p><button onClick={done ? null : handleClick}>Real</button>
                    <button onClick={done ? null : handleClick}>Fake</button></p>                    
                </div>
            </div>
            <div className={`container circle-wrap resultWrap ` + (showScores ? 'circle--left' : 'hidden')}>
                <h1>{ result }</h1>
                <h2 className={result ? '' : 'hidden'}>
                    <span>Your final score is</span>&nbsp;
                    <span className={showScores ? '' : 'wait'}>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                    </span>&nbsp;<span className="scoreWrap hidden">{score}/{imgLen}</span>
                </h2>
                <p className={`summarySpan ` + (summary.length ? '' : 'hidden')}>{summary}</p>
            </div>
        </div>
	);
};

export default Quiz;