import { useState } from 'react';
import { Form } from "react-router-dom";

const Home = () => {
    const [error, setError] = useState(0);

    const checkLength = (e) => {
        let newVal = e.target.value;
        if(newVal.length > 20) {
            e.target.value = newVal.substring(0, 20);
            setError(true);
        }
    };

	return (
        <div className="pink-wrap">
            <div className="container home">
                <div className="title">
                    <h1>Hello<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></h1>
                </div>
                <div className="form">
                    <Form method="get" action="/hello">
                        <p>😃 What is your name?</p>                
                        <input type="text" name="firstname" autoFocus onChange={checkLength} />
                        <label htmlFor="firstname" className={error ? 'error' : 'hidden'}>Max length is 20 characters</label>
                        <button type="submit"><span>→</span></button>
                    </Form>
                </div>
            </div>
        </div>
	);
};

export default Home;