import { useState, useEffect } from 'react';

const TypedMessage = ({msg, done}) => {
    const [message, setMessage] = useState('');
    const [index, setIndex] = useState(0);
    const fullMsg = msg ?? "not set";
    const iMsgLen = fullMsg.length;
    
    useEffect(() => {       
      // Use setInterval to create a typing effect
      const timeoutId = setInterval(() => {          
        if(index < iMsgLen) {
          setMessage(message => message + fullMsg[index]);
          setIndex(index + 1);
        }
        else {
          clearTimeout(timeoutId);
          done();
        }
      }, 30);
  
      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    }, [message, index, fullMsg, iMsgLen, done]); // Empty dependency array ensures the effect runs only once
  
    return (
      <p className="typed">{message}</p>
    );
  };

  export default TypedMessage;