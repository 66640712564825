import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import Home from './Pages/Home';
import Hello from './Pages/Hello';
import Quiz from './Pages/Quiz';
import Final from './Pages/Final';
import NotFound from './Pages/NotFound';

function App() {
  const takeRouter = createBrowserRouter(createRoutesFromElements(
    <>
      <Route path="/" element={<Home />} />
      <Route path="/hello" element={<Hello />} />
      <Route path="/quiz" element={<Quiz />} />
      <Route path="/final" element={<Final />} />
      <Route path="*" element={<NotFound />} />
    </>
  ));

  return (
      <div className="app">
        <RouterProvider router={takeRouter} />
      </div>
  );
}

export default App;
